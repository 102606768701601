import "@onramper/oui/lib/oui-global.css";
import React, { useEffect } from "react";
import { CookiesProvider, useCookies } from "react-cookie";
import { v4 as uuidv4 } from "uuid";
import { validate as uuidv4Validate } from "uuid";
import styles from "./common-styles.module.css";
import ErrorBoundary from "./components/common/ErrorBoundary/ErrorBoundary";
import ErrorView from "./components/views/ErrorView";
import InitScreen from "./components/views/InitScreen";
import initI18n from "./i18n/config";
import "./normalize.min.css";
import {
  NavigationContainer,
  NavigationContextProvider,
  NotificationContextProvider,
  ReactQueryProvider,
  ThemeContextProvider,
  TransactionContextProvider,
  IndexedDbProvider,
} from "./providers";
import { AuthContextProvider } from "./providers/AuthContextProvider";
import { DatadogRUMProvider } from "./providers/DatadogRUMProvider/DatadogRUMProvider";
import ParamContextProvider from "./providers/ParamContextProvider/ParamContextProvider";
import { SwapContextProvider } from "./providers/SwapContextProvider";
import { WalletConnectConditionalWrapper } from "./providers/WalletConnectProvider";
import { OnramperWidgetProps } from "./types";
import useUUIDCookie from "./hooks/useUUIDCookie";

const OnramperWidget: React.FC<OnramperWidgetProps> = (props) => {
  const [cookies] = useCookies(["uuid"]);
  useUUIDCookie(props.uuid ?? "");

  const renderGenericErrorView = () => (
    <div className={styles.view}>
      <ErrorView
        errorType="Generic"
        key="ErrorView"
        title={"Something went wrong."}
        description="An unexpected error occurred. Please try again."
        buttonLabel="Try again"
        buttonClickHandler={() => window.location.reload()}
      />
    </div>
  );

  useEffect(() => {
    initI18n(props.supportLocalization || false);
  }, [props.supportLocalization]);

  return (
    <ErrorBoundary fallBackComponent={renderGenericErrorView()}>
      <CookiesProvider>
        <IndexedDbProvider>
          <ReactQueryProvider>
            <AuthContextProvider>
              <ParamContextProvider widgetParams={props}>
                <WalletConnectConditionalWrapper>
                  <ThemeContextProvider>
                    <DatadogRUMProvider
                      uuid={cookies.uuid}
                      publicKey={props.apiKey || "N/A"}
                      originatingHost={props.originatingHost || "N/A"}
                    >
                      <NavigationContextProvider>
                        <TransactionContextProvider>
                          <SwapContextProvider>
                            <NotificationContextProvider>
                              <NavigationContainer home={<InitScreen />} />
                            </NotificationContextProvider>
                          </SwapContextProvider>
                        </TransactionContextProvider>
                      </NavigationContextProvider>
                    </DatadogRUMProvider>
                  </ThemeContextProvider>
                </WalletConnectConditionalWrapper>
              </ParamContextProvider>
            </AuthContextProvider>
          </ReactQueryProvider>
        </IndexedDbProvider>
      </CookiesProvider>
    </ErrorBoundary>
  );
};

// eslint-disable-next-line react/display-name
export default (props: OnramperWidgetProps) => <OnramperWidget {...props} />;
