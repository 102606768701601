import { Skeleton } from "@onramper/oui";
import React, { FC } from "react";
import { useTransactionContext } from "../../../../providers";
import styles from "./CurrencyConversion.module.css";
import { CurrencyConversionProps } from "./CurrencyConversionProps";
import { usePaymentMethods } from "../../../../hooks/usePaymentTypes";
import { useLanguage } from "../../../../hooks/useLanguage";

const CurrencyConversion: FC<CurrencyConversionProps> = (
  props: CurrencyConversionProps
) => {
  const { quote, loading } = props;
  const { transaction } = useTransactionContext();
  const { selectedFiat, selectedCrypto, selectedPaymentMethod } = transaction;
  const { isError: paymentMethodError } = usePaymentMethods();

  const { formatMessage } = useLanguage();

  if (paymentMethodError) return <></>;

  if (loading) {
    return (
      <div className={styles["currency-conversion"]}>
        <span className={styles.loader} />
        <span>{formatMessage("cryptoView.currencyConversion.loading")}</span>
      </div>
    );
  }

  if (!quote) return <></>;

  if (!isNaN(quote.rate)) {
    return (
      <span className={styles["currency-conversion"]}>
        1 {selectedCrypto?.code} ≈{" "}
        {typeof quote.rate === "number" && quote.rate?.toFixed(2)}{" "}
        {selectedFiat?.code}
      </span>
    );
  }
  if (quote.payout === null) {
    return null;
  }

  if (quote.errors) {
    return <div></div>;
  }
  return <Skeleton width="140px" height="20px" variant="text" />;
};

export default CurrencyConversion;
