import type { ReactNode } from "react";
import { ThemeName } from "./providers/ThemeContextProvider/types";
import { AuthErrorType } from "./providers/AuthContextProvider/types";

export type ChildrenProps = {
  children?: ReactNode;
};

export type CryptoCurrency = {
  id: string;
  name: string;
  symbol: string;
  icon?: string;
  code: string;
  network: string;
  networkDisplayName?: string;
  providerId?: string;
  address?: string | undefined;
};

export type FiatCurrency = {
  id: string;
  name: string;
  symbol: string;
  icon?: string;
  code: string;
};
export type RecurringPaymentMetadata = {
  startDate?: string | null;
  time?: string | null;
  period?: "monthly" | "weekly";
};

export type TransactionError =
  | "ClientLimitError"
  | "ServerLimitError"
  | "QuoteError";

export type Transaction = {
  selectedFiat: FiatCurrency | null;
  selectedCrypto: CryptoCurrency | null;
  fiatAmount: number;
  cryptoAmount: number;
  quotes: Quotes | null;
  selectedOnramp: Onramp | null;
  selectedPaymentMethod: PaymentMethod | null;
  selectedCountry: SupportedCountries | null;
  isRecurringPayment?: boolean;
  transactionType: TransactionType | null;
  recurringPaymentMetadata?: RecurringPaymentMetadata | null;
  error?: TransactionError | null;
  isOtcTxn: boolean; // adding temporary
};

export type Quote = {
  quoteId: string;
  ramp: string;
  rate: number;
  inCurrency: string;
  outCurrency: string;
  networkFee: number;
  payout: number;
  paymentMethod: string;
  availablePaymentMethods: PaymentMethod[];
  recommendations: Recommendation[];
  errors: QuoteError[];
};

export type Quotes = Quote[];

export type PaymentCurrencyStatus =
  | "SourceAndDestSupported"
  | "DestNotSupported"
  | "NA";

export type PaymentDetail = {
  currencyStatus: PaymentCurrencyStatus;
  limits: any;
};

export type PaymentMethod = {
  paymentTypeId: string;
  name: string;
  description?: string;
  icon?: SvgrComponent;
  details?: PaymentDetail;
};

export type Recommendation =
  | "LowKyc"
  | "SuccessfullyCompleted"
  | "ClientPreference"
  | "SuccessRate"
  | "BestPrice";

export type QuotesErrorType =
  | "LimitMismatch"
  | "NoSupportedPaymentFound"
  | "QuoteParameterMismatch"
  | "OnrampQuoteFetchFailed"
  | "NoCurrencyLimitsFound";

export type QuoteError = {
  errorId: number;
  message: string;
  type: QuotesErrorType;
  [x: string]: unknown;
};

export type Onramp = {
  id: string;
  name: string;
  icon: string;
  supportLink?: string;
  rate: number;
  payout: number;
  availablePaymentMethods: PaymentMethod[];
  isAvailable?: boolean;
  isMock?: boolean;
  errors?: QuoteError[];
  recommendations?: Recommendation[];
  quoteId?: string;
};

export type OnrampRecommendation = {
  [key in Recommendation]: string[];
};

export type Defaults = {
  countryCode?: string;
  source: string;
  target: string;
  amount: string;
  paymentMethod: string;
  provider: string;
  country: string;
  disableDeviceSpecificRecommendation?: boolean;
};

export type SupportedCountries = {
  countryCode: string;
  countryName: string;
};

export type SupportedLocales = {
  localeCode: string;
  localeName: string;
};

export type BuyParams = {
  defaultCrypto?: string | null;
  defaultFiat?: string | null;
  defaultAmount?: number;
  wallets?: string | null;
  walletAddressTags?: string | null;
  networkWallets?: string | null;
  onlyCryptos?: string | null;
  onlyCryptoNetworks?: string | null;
  excludeCryptos?: string | null;
  excludeCryptoNetworks?: string | null;
  excludeFiats?: string | null;
  onlyFiats?: string | null;
  excludePaymentMethods?: string | null;
  defaultPaymentMethod?: string | null;
  onlyOnramps?: string | null;
  isAddressEditable?: boolean;
  isAmountEditable?: boolean;
  preventTxnClose?: boolean;
  recommendation?: string | null;
  supportRecurringPayments?: boolean;
  signature?: string | null;
  enableAuth?: boolean;
};

export type SellParams = {
  defaultCrypto?: string | null;
  defaultFiat?: string | null;
  defaultAmount?: number;
  onlyCryptos?: string | null;
  onlyCryptoNetworks?: string | null;
  excludeCryptos?: string | null;
  excludeCryptoNetworks?: string | null;
  excludeFiats?: string | null;
  onlyFiats?: string | null;
  excludePaymentMethods?: string | null;
  defaultPaymentMethod?: string | null;
  onlyOfframps?: string | null;
  isAmountEditable?: boolean;
  recommendation?: string | null;
};

export type OnramperWidgetProps = {
  buy: BuyParams;
  sell: SellParams;
  apiKey?: string | null;
  country?: string | null;
  supportSell?: boolean;
  supportBuy?: boolean;
  supportSwap?: boolean;
  skipTransactionScreen?: boolean;
  txnRedirect?: boolean;
  preventTxnClose?: boolean;
  partnerContext?: string | null;
  themeName?: ThemeName;
  containerColor?: string | null;
  cardColor?: string | null;
  primaryColor?: string | null;
  secondaryColor?: string | null;
  primaryTextColor?: string | null;
  secondaryTextColor?: string | null;
  primaryBtnTextColor?: string | null;
  borderRadius?: string | null;
  wgBorderRadius?: string | null;
  enableCountrySelector?: boolean;
  successRedirectUrl?: string | null;
  failureRedirectUrl?: string | null;
  offrampCashoutRedirectUrl?: string | null;
  originatingHost?: string | null;
  supportLocalization?: boolean;
  supportOtcTxn?: boolean;
  enableAuth?: boolean;
  txnType?: TransactionType;
  txnAmount?: number;
  txnFiat?: string | null;
  txnCrypto?: string | null;
  txnPaymentMethod?: string | null;
  txnOnramp?: string | null;
  txnInput?: "source" | "destination";
  txnOfframp?: string | null;
  uuid?: string | null;
};

export type PaymentMethodMetadata = {
  [key: string]: PaymentMethod;
};

export function isCryptoCurrency(
  obj: CryptoCurrency | FiatCurrency
): obj is CryptoCurrency {
  return "network" in obj;
}

export type CheckoutIntentBody = {
  sessionId?: string;
  onramp: string;
  source: string;
  destination: string;
  amount: number;
  type: string;
  country?: string;
  paymentMethod: string;
  supportedParams?: SupportedParams;
  originatingHost?: string;
  wallet?: Wallet;
  expiringTime?: number;
  network?: string;
  uuid?: string;
  partnerContext?: string;
  signature?: string;
  signContent?: string;
  // host?: string;
};

export type SupportedParams = {
  theme?: Theme;
};

export type Theme = {
  isDark?: boolean;
  themeName?: string | null;
  primaryColor?: string | null;
  secondaryColor?: string | null;
  primaryTextColor?: string | null;
  secondaryTextColor?: string | null;
  cardColor?: string | null;
  borderRadius?: number | null;
};

export type Wallet = {
  address: string;
  memo?: string;
};

export type CheckoutIntentResponse = {
  message: {
    sessionInformation: any;
    status: boolean;
    transactionInformation: any;
    validationInformation: true;
  };
};

export type SignatureVerificationRequest = {
  signature: string;
  signContent: string;
};

export type SignatureVerificationResponse = { message: string };

export type TransactionType = "buy" | "sell";

export type NotificationId = "NOBUYQT" | "NOSELLQT";

export type SwapStatus = "completed" | "loading" | "inactive" | "failed";

export type SwapStep = {
  stepLabel: string;
  status: SwapStatus;
  completed: boolean;
};

export type Auth = {
  isAuthenticated: boolean;
  authToken: string | null;
  otp: string | null;
  email: string | null;
  onramperId: string | null;
  authError: AuthErrorType;
};

export type SignInResponse = {
  onramperId: string;
  status: "success" | "failed";
};

export type VerifyOtpResponse = {
  onramperId: string;
  jwt: string;
  status: "success" | "failed";
};
